import { useLazyQuery } from '@apollo/client';
import { Container } from 'components/box';
import homePageQuery from 'queries/homePage.graphql';
import { useEffect } from 'react';
import { QueryHomePageOutput, QueryHomePageArgs } from 'types/types';
import { useCustomerContext, useStaffPreview } from 'hooks/customer';
import { useErrorNotificationHandler } from 'hooks/handle-errors';
import { setGTMPageName } from '../util/tag-manager';
import { fetchGraphQl, parseQueryAST, isAbortError } from '../util/graphql';
import Loading from 'components/loading';
import QueryError from 'components/query-error';
import { HomePage } from 'components/_pages/home/types';
import { homePageProps } from 'components/_pages/home/utils';
import Body from 'components/_pages/home/body';
import { PageProps } from 'types/types';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { AlgoliaIndexEnum } from 'types/interface';
import { PageNamesEnum } from 'types/third-party/gtm';
import { logError } from '../util/error-logger';
import { OrganizationSchema } from 'components/_global/schema';
import { cacheControl } from 'constants/cache-control';
import { logInvalidJSON } from '../util/log-invalid-json';

setGTMPageName(PageNamesEnum.homepage);

interface HomePageProps {
  homePage?: HomePage;
}

/**
 * Takes the SSR props and passes them down to the body.
 * But on client will also check if it needs to load the staff preview;
 * and if so it will pass on the preview props instead.
 * Only down here coz it needs the `homePageProps` function.
 */
const BodyWrapper = ({ homePage: homePageSSR }: HomePageProps) => {
  const context = useCustomerContext();
  const mountErrors = useErrorNotificationHandler();

  const [previewHomePage, { loading, error, data }] = useLazyQuery<
    QueryHomePageOutput,
    QueryHomePageArgs
  >(homePageQuery, { context, errorPolicy: 'all', fetchPolicy: 'no-cache' });

  useStaffPreview(({ date }) => previewHomePage({ variables: { date } }));

  useEffect(() => {
    error && mountErrors([new Error('Preview failed')]);
  }, [error, mountErrors]);

  const homePage =
    data && data.products && data.products.length > 0
      ? homePageProps(data)
      : homePageSSR;

  if (!homePage) {
    return (
      <Container py={[3, null, 3]}>
        <QueryError />
      </Container>
    );
  }

  return (
    <Loading iconTop={200} isLoading={loading} loadingText="Preview loading...">
      <Body
        {...homePage}
        isLazy={!data || !data.products || data.products.length === 0}
      />
      <OrganizationSchema />
    </Loading>
  );
};

export async function getServerSideProps({
  res,
}: GetServerSidePropsContext): Promise<
  GetServerSidePropsResult<HomePageProps & PageProps>
> {
  try {
    const [query, operationName] = await parseQueryAST(
      homePageQuery,
      'homePage'
    );

    const { data, errors } = await fetchGraphQl<QueryHomePageOutput>({
      operationName,
      query,
      logInvalidJSON,
    });

    // only fail if there is no data
    if (!data || !data.products) {
      // we know the homepage should technically always have products
      // so we can assume that this is temporary
      res.statusCode = 503;
      res.setHeader('cache-control', cacheControl.temporary);

      // log error
      if (errors && errors.length > 0) {
        const [firstError] = errors;
        logError(firstError.message, 'homepage.query');
      } else {
        logError('No data returned for homepage', 'homepage.query');
      }

      // return empty props so we can render a failure page
      return { props: {} };
    }

    // go ahead
    res.setHeader('cache-control', cacheControl.dynamic);

    return {
      props: {
        homePage: homePageProps(data),
        meta: {
          messages: data.messages || null,
          isAnotherDayOnlyActive: data.configs?.anotherDayOnly?.isActive,
        },
        layout: {
          searchIndexName: AlgoliaIndexEnum.Homepage,
          selectedTopTab: 'home',
          categories: data.categories,
          hasScrollButton: true,
          hasAppDownloadWidget: true,
        },
      },
    };
  } catch (e) {
    res.statusCode = isAbortError(e) ? 503 : 500;
    res.setHeader('cache-control', cacheControl.temporary);

    logError(e, 'homepage.catch');

    // return empty props so we can render a failure page
    return { props: {} };
  }
}

export default BodyWrapper;
